import {Expose, Type} from 'class-transformer';
import {EAccessTag} from "./project.model";

export enum EMemberRoles {
  ProjectOwner = 'project_owner',
  ProjectAdmin = 'project_admin',
  ProjectContractor = 'contractor',
  Custom = 'custom',
}

export interface IMemberProjects {
  id: number;
  name: string;
  role_v2: string;
  role: string;
  project_id: number;
  admins_project_id: number;
  project_accesses_attributes: IAccessesAttributes[];
}

export interface IAccessesAttributes {
  acc_tag: string;
}

export interface IMembers {
  id: number;
  deleted_at: string | null;
  name: string;
  email: string;
  role: string;
  joined_at?: string;
  last_activity?: string;
  projects?: IMemberProjects[];
  registered?: boolean;
  admins_firm_id?: number;
}

export class MembersModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'deleted_at' })
  deletedAt!: string | null;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'admins_firm_id' })
  adminsFirmId!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'company' })
  company!: { id: number, title: string } | null;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'invite_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'joined_at' })
  createdAt!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'projects' })
  projects!: IMemberProjects[];
  @Expose({ name: 'main_owner' })
  mainOwner!: boolean;
  @Expose({ name: 'registration_link' })
  registrationLink!: string;

  selected = false;
}

export class GuestsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'role' })
  role!: string;

  selected = false;
}

export interface IMemberCustomList {
  value: string;
  label: string;
  id: string;
  disabled: boolean;
}

export const memberCustomList: IMemberCustomList[] = [
  { value: EAccessTag.Overview, label: 'Entity.Overview', id: 'cy-access-overview-checkbox', disabled: false },
  { value: EAccessTag.Units, label: 'Title.Units', id: 'cy-access-units-checkbox', disabled: false },
  { value: EAccessTag.Users, label: 'Title.Users', id: 'cy-access-users-checkbox', disabled: false },
  { value: EAccessTag.Timeline, label: 'Entity.Timeline', id: 'cy-access-timeline-checkbox', disabled: false },
  { value: EAccessTag.Documents, label: 'Entity.Documents', id: 'cy-access-documents-checkbox', disabled: false },
  { value: EAccessTag.FDV, label: 'Title.FDV', id: 'cy-access-fdvs-checkbox', disabled: false },
  { value: EAccessTag.Updates, label: 'Title.Newsletter', id: 'cy-access-updates-checkbox', disabled: false },
  { value: EAccessTag.PracticalInfo, label: 'Title.Practical_info', id: 'cy-access-practical_info-checkbox', disabled: false },
  { value: EAccessTag.Monitoring, label: 'Entity.Monitoring', id: 'cy-access-monitoring-checkbox', disabled: false },
  { value: EAccessTag.Threads, label: 'Title.Messages', id: 'cy-access-messages-checkbox', disabled: false },
  { value: EAccessTag.Options, label: 'Entity.Options', id: 'cy-access-options-checkbox', disabled: false },
  { value: EAccessTag.Illustrations, label: 'Entity.Illustrations', id: 'cy-access-illustrations-checkbox', disabled: false },
  { value: EAccessTag.Surveys, label: 'Title.Surveys', id: 'cy-access-surveys-checkbox', disabled: false },
  { value: EAccessTag.Tasks, label: 'Entity.Tasks', id: 'cy-access-tasks-checkbox', disabled: false },
  { value: EAccessTag.Reclamations, label: 'Title.Reclamations', id: 'cy-access-reclamations-checkbox', disabled: false },
  { value: EAccessTag.Meetings, label: 'Title.Meetings', id: 'cy-access-meetings-checkbox', disabled: false },
  { value: EAccessTag.Contacts, label: 'Title.Contacts', id: 'cy-access-contacts-checkbox', disabled: false },
  { value: EAccessTag.ChangeRequests, label: 'Title.Change_requests', id: 'cy-access-change-requests-checkbox', disabled: false },
];

interface IFirmsAttributes {
  id: number;
  role: string;
}

interface IProjectAttributes {
  id: number;
  role: string;
}

export interface IMemberUpdates {
  member: {
    email?: string;
    name?: string;
    phone?: string;
    admins_firms_attributes?: IFirmsAttributes[];
    admins_projects_attributes?: IProjectAttributes[];
  };
}

export interface IProjectMemberRoleUpdate {
  member: {
    role: string;
  };
}

export interface IInviteMember {
  admins_firms_attributes: {
    role: string;
  };
  email: string;
  name: string;
}

export class ClientUnitMemberUpdateModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'main_owner' })
  mainOwner!: false;
  @Expose({ name: 'will_project_owner' })
  willProjectOwner!: false;
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'sale_status' })
  saleStatus!: string;
  @Expose({ name: 'company' })
  company!: string | null;
}

export class ClientMemberUpdateModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'user_type' })
  userType!: string;
  @Expose({ name: 'units' })
  @Type(() => ClientUnitMemberUpdateModel)
  units!: ClientUnitMemberUpdateModel[];
}

export interface IClientCreateMember {
  name: string;
  email: string;
  role: string;
}
