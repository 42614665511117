import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { Editor } from 'tinymce';

@Injectable()
export class TextEditorService {
  constructor(private renderer: Renderer2) {}

  private fileWrapper!: HTMLCollectionOf<Element>;
  private floorWrapper!: HTMLCollectionOf<Element>;

  public updateIframeFiles<T extends EventObj<Document> | Editor = EventObj<Document>>(
    editor: ElementRef,
    fileRef: ElementRef,
    tinymceEditor?: T
  ): void {
    const iFrame = editor.nativeElement.getElementsByClassName('tox-edit-area__iframe');
    if (!this.fileWrapper || !this.fileWrapper.length) {
      this.fileWrapper = fileRef?.nativeElement.getElementsByClassName('input-file__preview');
    }
    if (this.fileWrapper[0]) {
      this.renderer.appendChild(iFrame[0]?.contentWindow?.document.getElementsByTagName('html')[0], this.fileWrapper[0]);
      const fileElement = fileRef.nativeElement.getElementsByClassName('input-file__item-preview');
      const index = fileElement.length - 1;
      const fileCopy = fileElement[index];
      if (fileCopy) {
        this.renderer.appendChild(this.fileWrapper[0], fileCopy);
      }
    }
    if (tinymceEditor && 'execCommand' in tinymceEditor) {
      (<Editor>tinymceEditor).execCommand('mceAutoResize');
    }
    if (tinymceEditor && 'editor' in tinymceEditor) {
      (<EventObj<Document>>tinymceEditor).editor.execCommand('mceAutoResize');
    }
  }

  public updateIFrameFloorPlan<T extends EventObj<Document> | Editor = EventObj<Document>>(
    editor: ElementRef,
    fileRef: ElementRef,
    tinymceEditor?: T
  ): void {
    const iFrame = editor.nativeElement.getElementsByClassName('tox-edit-area__iframe');
    if (!this.floorWrapper || !this.floorWrapper.length) {
      this.floorWrapper = fileRef?.nativeElement.getElementsByClassName('floor-list');
    }
    if (this.floorWrapper[0]) {
      this.renderer.appendChild(
        iFrame[0].contentWindow?.document.getElementsByTagName('html')[0],
        this.floorWrapper[0]
      );
      const floorElement = fileRef.nativeElement.getElementsByClassName('floor-list__item');
      const index = floorElement.length - 1;
      const floorCopy = floorElement[index];
      if (floorCopy) {
        this.renderer.appendChild(this.fileWrapper[0], floorCopy);
      }
    }
    if (tinymceEditor && 'execCommand' in tinymceEditor) {
      (<Editor>tinymceEditor).execCommand('mceAutoResize');
    }
    if (tinymceEditor && 'editor' in tinymceEditor) {
      (<EventObj<Document>>tinymceEditor).editor.execCommand('mceAutoResize');
    }
  }
}
