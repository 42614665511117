<atl-ngb-modal-header [title]="title" (closeModalEvent)="closeModal()" [isClient]="isClient"></atl-ngb-modal-header>

<form [formGroup]="form">
  <div class="message-modal__control">
    <label *ngIf="showLabel" for="message-modal-decline-comment" class="message-modal__form-label">
      <span>{{ 'Shared.Entity.Comment' | translate }}</span>
    </label>
    <atl-custom-text-area
      tabindex="0"
      formControlName="comment"
      id="message-modal-decline-comment"
      [placeholder]="placeholder"
      [maxLength]="maxCommentLength"
      [class.no-label]="!showLabel"
    ></atl-custom-text-area>
  </div>
</form>

<atl-ngb-modal-footer *ngIf="!onlyView">
  <div class="w-100 d-flex justify-content-end">
    <button
      (click)="closeModal()"
      class="newest-button button-gray mr-16"
    >
      {{ 'Shared.Button.Cancel' | translate }}
    </button>
    <button
      (click)="changeStatus()"
      class="fz-16 lh-24 br-32"
      [class.button-red.newest-button-spinner]="!blueButton"
      [class.newest-button]="blueButton"
      [disabled]="form.invalid || changedInput"
    >
      {{ button | translate }}
    </button>
  </div>
</atl-ngb-modal-footer>
