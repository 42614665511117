import { Expose, Transform, Type } from 'class-transformer';

import { IUnitItem, IUnitProject } from '../interface/chat-filter.interface';
import { IGlobalChangeRequestProject } from './chenge-request.model';
import { FileModel } from './file.model';
import { ClientCategory } from './layout-option.model';
import { PreviewModel } from './product.model';
import { SelectedPackage } from './selected-package.model';
import { VendorModel } from './vendor.model';
import { EPriceOfferItemStatus } from './price-offer.model';

export const statusOfferClasses = {
  [EPriceOfferItemStatus.Offered]: 'status__offered',
  [EPriceOfferItemStatus.Accepted]: 'status__offer-approved',
  [EPriceOfferItemStatus.Expired]: 'status__offer-expired',
  [EPriceOfferItemStatus.Declined]: 'status__offer-declined',
  [EPriceOfferItemStatus.Requested]: 'status__offer-requested',
  'default': 'status__new'
} as const;

export const statusOfferTexts = {
  [EPriceOfferItemStatus.Offered]: 'Title.Offered',
  [EPriceOfferItemStatus.Accepted]: 'Shared.Status.Options.Completed',
  [EPriceOfferItemStatus.Expired]: 'Shared.Change_request.Offer.Expired',
  [EPriceOfferItemStatus.Declined]: 'Shared.Change_request.Status.Declined',
  [EPriceOfferItemStatus.Requested]: 'Shared.Entity.Requested',
  'default': 'Entity.New'
} as const;

export enum EWishlistStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  NotSelected = 'not_selected',
  Blocked = 'blocked',
  NotCompleted = 'not_completed',
  Signed = 'signed',
  NotSigned = 'not_signed',
  ReadyForSigning = 'ready_for_signing',
  NotOpened = 'not_opened',
}

export interface IGroupedCompletedItem {
  categoryName: string;
  totalPrice: number;
  items: WishlistItem[];
}

class PriceOfferModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'is_offer_accepted' }) isOfferAccepted!: boolean;
  @Expose({ name: 'viewed_by_user' }) viewed!: boolean;
}

export enum EWishlistItemType {
  LayoutOptionProduct = 'layout_option_product',
  CustomProduct = 'custom_product',
  NoStandardDelivery = 'no_standard_delivery',
}

export enum ESigningStatus {
  NotSigned = 'not_signed',
  ReadyForSigning = 'ready_for_signing',
  Signed = 'signed',
}

export interface IPriceRequestFilter {
  projects?: number[];
  units: number[];
  status: string[];
  options: number[];
}

export interface IPriceRequestListsForFilter {
  statuses: PriceFilterStatusModel[];
  units: IUnitItem[];
  projects: IGlobalChangeRequestProject[];
  options: IOptionItem[];
}

interface IOptionItem {
  id: number;
  name: string;
  project: IUnitProject;
  selected?: boolean;
}

export class PriceFilterStatusModel {
  count!: number;
  name!: string;
  active = false;
  @Expose({ name: 'localized_name' }) localizedName!: string;
}

export class FirmWishlistItem {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'layout_option_id' }) layoutOptionId!: string;
  @Expose({ name: 'layout_type_id' }) layoutTypeId!: string;
  @Expose({ name: 'option_store_name' }) optionStoreName!: string;
  @Expose({ name: 'price_requested' }) priceRequested!: boolean;
  
  @Expose({ name: 'price_offer' }) priceOffer!: {
    id: number;
    amount: number;
    description: string | null;
    status: string;
  };
  @Expose({ name: 'product_type_name' }) productTypeName!: string;
  @Expose({ name: 'project' }) project!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'unit' }) unit!: {
    id: number;
    identifier: string;
    project_name: string;
  };

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.versions?.w260?.url || 'assets/no_images.png', { toClassOnly: true })
  preview!: string;

  @Expose({ name: 'product' }) product!: {
    id: number;
    description: string;
    title: string;
  };

  @Expose({ name: 'status' }) status!: EPriceOfferItemStatus;
  statusClass!: string;
  statusText!: string;
}

export class WishlistItem {
  @Expose({ name: 'currency' }) currency!: string;
  @Expose({ name: 'customer_price' }) customerPrice!: string;
  @Expose({ name: 'customer_price_with_vat' }) customerPriceWithVat!: string;
  @Expose({ name: 'category_id' }) categoryId!: number;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'signing_status' }) signingStatus!: string;
  @Expose({ name: 'category_name' }) categoryName!: string;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'layout_product_type_item_id' }) layoutProductTypeItemId!: number;
  @Expose({ name: 'price' }) price!: string;
  @Expose({ name: 'product_type_name' }) productTypeName!: string;
  @Expose({ name: 'quantity' }) quantity!: number | null;
  @Expose({ name: 'standard' }) standard!: boolean;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'item_type' }) itemType!: string;
  @Expose({ name: 'is_wall_square' }) isWallSquare!: boolean;
  @Expose({ name: 'price_type' }) priceType!: string;
  @Expose({ name: 'area_size' }) areaSize!: string | null;
  @Expose({ name: 'layout_product_type_expired' }) layoutProductTypeExpired!: boolean;
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'auto_completed' }) autoCompleted!: boolean;
  @Expose({ name: 'extra_amount' }) extraAmount!: number;
  @Expose({ name: 'free_text' }) freeText!: string | null;
  @Expose({ name: 'product_type_due_date' }) productTypeDueDate!: string | null;
  @Expose({ name: 'price_requested' }) priceRequested!: boolean | null;
  @Expose({ name: 'price_offer' })
  @Type(() => PriceOfferModel)
  priceOffer!: PriceOfferModel | null;

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.w260?.url || 'assets/no_images.png', { toClassOnly: true })
  preview!: string;
  selected = true;
  showDropdown = false;
  fileResourcesAttributes?: FileModel[];
}

class CustomProductModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'area_size' }) areaSize!: number;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'product_type_due_date' }) productTypeDueDate!: number | null;
  @Expose({ name: 'quantity' }) quantity!: number;
  @Expose({ name: 'status' }) status!: string;

  @Expose({ name: 'vendor' }) vendor!: VendorModel;
  @Expose({ name: 'description' }) description!: string;

  asInput = false;
  changedValue = false;
  selected = true;
}

export class WishlistModel {
  @Expose({ name: 'allow_bank_id' })
  allowBankId!: boolean;
  @Expose({ name: 'categories' })
  @Type(() => Wishlist)
  categories!: Wishlist[];
  @Expose({ name: 'has_completed_item' })
  hasCompletedItem!: boolean;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'in_progress_items_count' })
  inProgressItemsCount!: number;
  @Expose({ name: 'needed_offers_count' })
  neededOffersCount!: number;
  @Expose({ name: 'new_items_count' })
  newItemsCount!: number;
  @Expose({ name: 'status' })
  status!: string;
  @Expose({ name: 'submitted_once' })
  submittedOnce!: boolean;
  @Expose({ name: 'without_answers_offers_count' })
  withoutAnswersOffersCount!: number;

  @Expose({ name: 'completed_items' }) @Type(() => WishlistItem) completedItems!: WishlistItem[];
  @Expose({ name: 'not_completed_items' }) @Type(() => WishlistItem) notCompletedItems!: WishlistItem[];
  @Expose({ name: 'all_change_requests_price' }) allChangeRequestsPrice!: number;
  @Expose({ name: 'change_requests' }) changeRequests!: {
    id: number;
    price: number;
    identifier: string;
  }[];

  @Expose({ name: 'all_completed_items_price' }) allCompletedItemsPrice!: number;
  @Expose({ name: 'all_in_progress_items_price' }) allInProgressItemsPrice!: number;

  @Expose({ name: 'category_id' }) categoryId!: number;

  @Expose({ name: 'blocked_layout_product_types' }) blockedLayoutProductTypes!: {
    id: number;
    name: string;
  }[];

  @Expose({ name: 'total_price' }) totalPrice!: number;
  @Expose({ name: 'hide_confirmation_modal' }) hideConfirmationModal!: boolean;
  @Expose({ name: 'hide_request_price_confirmation_modal' }) hideRequestPriceConfirmationModal!: boolean;
  @Expose({ name: 'client_categories' })
  @Type(() => ClientCategory)
  clientCategories!: ClientCategory[];

  @Expose({ name: 'selected_package' })
  @Type(() => SelectedPackage)
  selectedPackage!: SelectedPackage | null;
}

export class Wishlist {
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'room_size' }) roomSize!: string;
  @Expose({ name: 'room_walls_area_total' }) roomWallsAreaTotal!: number;
  @Expose({ name: 'total_price' }) totalPrice!: number;
  @Expose({ name: 'total_in_progress_price' }) totalInProgressPrice!: number;
  @Expose({ name: 'items' }) @Type(() => WishlistItem) items!: WishlistItem[];
  @Expose({ name: 'products' }) @Type(() => WishlistItem) products!: WishlistItem[];
  selected = true;

  @Expose({ name: 'custom_products' })
  @Type(() => CustomProductModel)
  customProducts!: CustomProductModel[];

  allSelected = false;
  atLeastOneSelected = false;
  allDisabled = false;
}

export class WishlistTotalPrice {
  @Expose({ name: 'in_progress_total_price' }) inProgressTotalPrice!: number;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'total_price' }) totalPrice!: number;
}

export interface UpdateWishlistProps {
  projectId: number;
  unitId: number;
  productTypeItemId: number;
  priceRequested?: boolean;
  status?: string;
  layoutTypeId?: number;
  extraAmount?: number;
  showImages?: boolean;
}
