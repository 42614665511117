<div
  #dropdown="ngbDropdown"
  ngbDropdown
  [autoClose]="true"
  container="body"
  [placement]="placement"
  (click)="$event.stopImmediatePropagation()"
  class="more-dropdown"
>
  <atl-header-icon
    ngbDropdownToggle
    icon="/assets/dropdown-dot-2.svg"
    [ngbTooltip]="'Shared.Button.More' | translate"
    [openDelay]="openTooltipDelay"
    [class.active]="dropdown._open"
    container="body"
    [ngClass]="{client}"
  ></atl-header-icon>
  <div ngbDropdownMenu [attr.aria-labelledby]="'more-dropdown'" [ngClass]="{client}">

    <div *ngIf="header" class="more-dropdown__header">
      <div *ngIf="user" class="d-flex align-items-center min-w-0">
        <atl-firm-icon
          [model]="user"
          [isUser]="true"
          class="mr-8"
        ></atl-firm-icon>
        <div class="d-flex flex-column min-w-0">
          <span
            #name
            class="d-block fz-12 lh-16 fw-600 text-black-810 ellipsis"
            [ngbTooltip]="user"
            [openDelay]="openTooltipDelay"
            [disableTooltip]="name.offsetWidth >= name.scrollWidth"
          >
            {{'Shared.Entity.Created_by' | translate}} {{user}}
          </span>
          <span class="d-block fz-12 lh-16 text-black-810">
            {{ date | dateTimePipe }}
          </span>
        </div>
      </div>
      <span *ngIf="!user" class="fz-12 lh-16 text-black-810" [class.fw-600]="!user">
        {{ 'Entity.Created_on' | translate }} {{ date | dateTimePipe }}
      </span>
    </div>

    <div *ngIf="body" class="more-dropdown__items">
      <ng-content select="[item]"></ng-content>
    </div>

    <div *ngIf="footer" class="more-dropdown__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
