import { Expose, Type } from 'class-transformer';

import { EDateFormat } from '../interface/date-format';
import { AdminModel } from './admin.model';
import { Owner } from './owner.interface';
import { ITablePagination } from './table-pagination.interface';

export enum EProjectStatus {
  Deleted = 'deleted',
  Live = 'live',
  Offline = 'offline',
}

export interface IProjectUpdate {
  project: Partial<{
    name?: string;
    country?: string;
    address?: string;
    longitude?: number;
    latitude?: number;
    currency?: string;
    timeline_visibility?: boolean;
    preview?: FormData;
    estimated_date_format: string;
    allow_bank_id?: boolean;
    standard_product_preselection: boolean;
    show_category_packages: boolean;
    city?: string;
    zip_code?: string;
  }>;
}

interface IProjectOwnerRoleData {
  id: number;
  project_id: number;
}

export interface IProjectRoleUpdate {
  members: IProjectOwnerRoleData[];
}

export interface IProject {
  address: string;
  created_at: string;
  id: number;
  name: string;
  owner_id: number;
  preview: string;
  updated_at: string;
}

export interface IFullInfoClient {
  name: string;
  id: number;
  units: {
    id: number;
    identifier: string;
  };
}

export interface IHeaderDropdown {
  projectName: string;
  projectId: number;
  unitName: string;
  unitId: number;
  unitRole: string;
  layoutTypeId: number | null;
  layoutTypeStatus: LayoutTypeStatus;
}

export interface IClientProjectHeader {
  projectId: number;
  unitId?: number;
}

export interface IClientUnitHeader {
  id: number;
}

export interface IFirm {
  address: string;
  business_domain: string;
  country: string;
  created_at: string;
  currency: string;
  id: number;
  latitude: string;
  longitude: string;
  title: string;
  updated_at: string;
}

export enum ELayoutTypeStatus {
  Unpublished = 'unpublished',
  Published = 'published',
}

export type LayoutTypeStatus = 'unpublished' | 'published';

export interface ILayoutTypeData {
  layoutTypeId: number | null;
  layoutTypeStatus: LayoutTypeStatus;
}

export class FullInfoUnitMenu {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'user_role' }) userRole!: string;
  @Expose({ name: 'layout_type_id' }) layoutTypeId!: number | null;
  @Expose({ name: 'layout_type_status' }) layoutTypeStatus!: LayoutTypeStatus;
}

export class FullInfoClientModel {
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'units' })
  @Type(() => FullInfoUnitMenu)
  units!: FullInfoUnitMenu[];
}

export class FavouriteProject {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'current_role_v2' }) currentRoleV2!: string;
}

export class IProjectDetailPreviewModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'is_default' })
  isDefault?: boolean;
  @Expose({ name: 'cropped' })
  cropped!: {
    url: string;
  };
  @Expose({ name: 'w260' })
  w260!: {
    url: string;
  };
  @Expose({ name: 'w500' })
  w500!: {
    url: string;
  };
}

export class ProjectModel {
  @Expose({ name: 'address' })
  address: string;
  @Expose({ name: 'city' })
  city!: string;
  @Expose({ name: 'zip_code' })
  zipcode!: string;
  @Expose({ name: 'is_favorite' })
  isFavorite!: boolean;
  @Expose({ name: 'currency' })
  currency: string;
  @Expose({ name: 'country' })
  country: string;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'id' })
  projectId: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'owner_id' })
  ownerId: number;
  @Expose({ name: 'owner' })
  owner: Owner | undefined;
  @Expose({ name: 'preview' })
  @Type(() => IProjectDetailPreviewModel)
  preview: IProjectDetailPreviewModel;
  @Expose({ name: 'updatedAt' })
  updatedAt: string;
  @Expose({ name: 'status' })
  status: string;
  @Expose({ name: 'latitude' })
  latitude: number;
  @Expose({ name: 'longitude' })
  longitude: number;
  @Expose({ name: 'timeline_visibility' })
  timelineVisibility!: boolean;
  @Expose({ name: 'standard_product_preselection' })
  standardProductPreselection!: boolean;
  @Expose({ name: 'show_category_packages' })
  showCategoryPackages!: boolean;
  @Expose({ name: 'estimated_date_format' })
  estimatedDateFormat!: EDateFormat;

  @Expose({ name: 'firm' })
  firm!: IFirm;

  @Expose({ name: 'admins' })
  @Type(() => AdminModel)
  admins!: AdminModel[];

  @Expose({ name: 'current_project_accesses_attributes' })
  @Type(() => AccessModel)
  projectAccess: AccessModel[];
  @Expose({ name: 'current_role_v2' })
  currentRole: string;
  @Expose({ name: 'cropping_data' })
  croppingData: ICroppingData | null;
  @Expose({ name: 'company_name' })
  companyName!: string;
  @Expose({ name: 'org_number' })
  orgNumber!: string;
  @Expose({ name: 'sales_revenue' })
  salesRevenue!: string;
  @Expose({ name: 'sold_units_count' })
  soldUnitsCount!: number;
  @Expose({ name: 'units_count' })
  unitsCount!: number;
  @Expose({ name: 'allow_bank_id' })
  allowBankId?: boolean;
  @Expose({ name: 'health_rate' })
  healthRate!: string;
  @Expose({ name: 'open_issues_count' })
  openIssuesCount!: string;

  groupSelect = 'project';
  selected?: boolean;

  constructor() {
    this.address = '';
    this.createdAt = '';
    this.projectId = 0;
    this.name = '';
    this.ownerId = 0;
    this.preview = {
      url: '',
      cropped: { url: '' },
      w260: { url: '' },
      w500: { url: '' },
    };
    this.updatedAt = '';
    this.owner = undefined;
    this.status = '';
    this.latitude = 0;
    this.longitude = 0;
    this.currency = '';
    this.country = '';
    this.projectAccess = [];
    this.currentRole = '';
    this.croppingData = null;
  }
}

export interface ICroppingData {
  width: string;
  height: string;
  imagePosition: ICropPosition;
  cropperPosition: ICropPosition;
}

export interface ICropPosition {
  x1: string | number;
  x2: string | number;
  y1: string | number;
  y2: string | number;
}

export class AccessModel {
  @Expose({ name: 'acc_tag' })
  accTag!: EAccessTag;
}

export enum EAccessTag {
  Overview = 'overview',
  Timeline = 'timeline',
  Documents = 'documents',
  FDV = 'fdvs',
  Updates = 'updates',
  Economy = 'economy',
  PracticalInfo = 'practical_info',
  Monitoring = 'monitoring',
  Threads = 'messages',
  Options = 'options',
  Illustrations = 'illustrations',
  Surveys = 'surveys',
  Tasks = 'tasks',
  Reclamations = 'reclamations',
  Meetings = 'meetings',
  Units = 'units',
  ChangeRequests = 'change_requests',
  Users = 'users',
  Settings = 'settings',
  Contacts = 'contacts',
  MntNotify = 'mnt_notify',
}

export class IProjectOverviewDetailsModel {
  @Expose({ name: 'address' })
  address!: string;
  @Expose({ name: 'cropping_data' })
  croppingData?: Partial<ICroppingData>;
  @Expose({ name: 'estimated_date_format' })
  estimatedDateFormat!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'preview' })
  @Type(() => IProjectDetailPreviewModel)
  preview!: IProjectDetailPreviewModel;
  @Expose({ name: 'timeline_visibility' })
  timelineVisibility!: boolean;
  @Expose({ name: 'owner' })
  owner!: {
    id: number;
    name: string;
    color?: string;
    borderColor?: string;
  };
}

export class NotificationSettingsAdminModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;

  selected!: boolean;

  constructor() {
    this.selected = false;
  }
}

export class NotificationSettingsUnitAndGroupModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'type' })
  type!: string;
  @Expose({ name: 'unit_ids' })
  unitIds?: number[];

  selected!: boolean;

  constructor() {
    this.selected = false;
  }
}

export class ProjectNotificationSettingsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'event_type' })
  eventType!: string;
  @Expose({ name: 'send_email' })
  sendEmail!: boolean;
  @Expose({ name: 'send_in_app' })
  sendInApp!: boolean;
  @Expose({ name: 'send_web' })
  sendWeb!: boolean;
  @Expose({ name: 'localized_event_type' })
  localizedEventType!: string;
  @Expose({ name: 'main_admins_count' })
  mainAdminsCount!: number;
  @Expose({ name: 'main_admins' })
  @Type(() => NotificationSettingsAdminModel)
  mainAdmins!: NotificationSettingsAdminModel[];
  @Expose({ name: 'acc_tag_by_event_type' })
  accTag!: EAccessTag;
}

export class ProjectNotoficationSettingsResponseModel {
  @Expose({ name: 'main_admins_all_notifications' })
  @Type(() => AdminModel)
  mainAdminsAllNotifications!: Partial<AdminModel>[];
  @Expose({ name: 'mnt_notify_settings' })
  @Type(() => ProjectNotificationSettingsModel)
  mntNotifySettings!: ProjectNotificationSettingsModel[];
}

export class ProjectNotificationBuyerResponsesModel {
  @Expose({ name: 'allow_notifications_management' })
  allowNotifications!: boolean;
  @Expose({ name: 'mnt_notify_settings' })
  @Type(() => ProjectNotificationBuyerSettingsModel)
  notifySettings!: ProjectNotificationBuyerSettingsModel[];
}

export class ProjectNotificationBuyerSettingsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'event_type' })
  eventType!: string;
  @Expose({ name: 'send_email' })
  sendEmail!: boolean;
  @Expose({ name: 'send_in_app' })
  sendInApp!: boolean;
  @Expose({ name: 'send_web' })
  sendWeb!: boolean;
  @Expose({ name: 'localized_event_type' })
  localizedEventType!: string;
  @Expose({ name: 'selected_units_count' })
  selectedUnitsCount!: number;
  @Expose({ name: 'units_or_groups' })
  @Type(() => NotificationSettingsUnitAndGroupModel)
  unitsOrGroups!: NotificationSettingsUnitAndGroupModel[];

  disabled = false;
}

export const BLOCKED_EVENT_TYPES = {
  newVisual: 'new_visual',
  newPhaseStartedOnTimeline: 'cus_new_phase_started_on_the_timeline',
  newPhotoConstCam: 'cus_new_photo',
};

export const BLOCKED_ADMIN_EVENT_TYPES = {
  newReclamation: 'new_reclamation'
}

export class ProjectHealthDataModel {
  [key: string]: boolean;

  @Expose({ name: 'admins_projects' })
  adminsProjects!: boolean;
  @Expose({ name: 'buyers' })
  buyers!: boolean;
  @Expose({ name: 'file_documents' })
  fileDocuments!: boolean;
  @Expose({ name: 'layout_options' })
  layoutOptions!: boolean;
  @Expose({ name: 'phases' })
  phases!: boolean;
  @Expose({ name: 'practical_infos' })
  practicalInfos!: boolean;
  @Expose({ name: 'tasks' })
  tasks!: boolean;
  @Expose({ name: 'units' })
  units!: boolean;
  @Expose({ name: 'updates' })
  updates!: boolean;
  @Expose({ name: 'visuals' })
  visuals!: boolean;
  @Expose({ name: 'manager_contacts' })
  managerContacts!: boolean;
  @Expose({ name: 'project_illustrations' })
  projectIllustrations!: boolean;
}

export class ProjectHealthItemModel {
  icon!: string;
  key!: string;
  title!: string;
  link!: string;
  completed!: boolean;
  disabled!: boolean;
  accTag!: string;
}

export interface GetProjectsProps {
  search?: string;
  projectId?: number;
  isOwnProjects?: boolean;
  isFavProjects?: boolean;
  sortBy?: string;
  pagination?: ITablePagination;
  noEmit?: boolean;
}
